<template>
    <main class="content container">
        <div v-if="orderInfo" class="content__top">
            <ul class="breadcrumbs">
                <li class="breadcrumbs__item">
                    <router-link class="breadcrumbs__link" :to="{name: 'main'}">
                        Каталог
                    </router-link>
                </li>
                <li class="breadcrumbs__item">
                    <router-link class="breadcrumbs__link" :to="{name: 'cart'}">
                        Корзина
                    </router-link>
                </li>
                <li class="breadcrumbs__item">
                    <a class="breadcrumbs__link">
                        Оформление заказа
                    </a>
                </li>
            </ul>

            <h1 class="content__title">
                Заказ оформлен <span>№ {{ orderInfo.id }}</span>
            </h1>
        </div>

        <section v-if="orderInfo" class="cart">
            <form class="cart__form form" action="#" method="POST">
                <div class="cart__field">
                    <p class="cart__message">
                        Благодарим за&nbsp;выбор нашего магазина. На&nbsp;Вашу почту придет письмо с&nbsp;деталями заказа.
                        Наши менеджеры свяжутся с&nbsp;Вами в&nbsp;течение часа для уточнения деталей доставки.
                    </p>

                    <ul class="dictionary">
                        <li class="dictionary__item">
                            <span class="dictionary__key">Получатель</span>
                            <span class="dictionary__value">{{ orderInfo.name }}</span>
                        </li>
                        <li class="dictionary__item">
                            <span class="dictionary__key">Адрес доставки</span>
                            <span class="dictionary__value">{{ orderInfo.address }}</span>
                        </li>
                        <li class="dictionary__item">
                            <span class="dictionary__key">Телефон</span>
                            <span class="dictionary__value">{{ orderInfo.phone }}</span>
                        </li>
                        <li class="dictionary__item">
                            <span class="dictionary__key">Email</span>
                            <span class="dictionary__value">{{ orderInfo.email }}</span>
                        </li>
                        <li class="dictionary__item">
                            <span class="dictionary__key">Способ оплаты</span>
                            <span class="dictionary__value">{{ orderInfo.deliveryType.title }}</span>
                        </li>
                        <li class="dictionary__item">
                            <span class="dictionary__key">Ваш комментарий:</span>
                            <span class="dictionary__value">{{ orderInfo.comment }}</span>
                        </li>
                        <li class="dictionary__item">
                            <span class="dictionary__key">Статус заказа:</span>
                            <span class="dictionary__value">{{ orderInfo.status.title }}</span>
                        </li>
                    </ul>
                </div>

                <div class="cart__block">
                    <ul class="cart__orders">
                        <li class="cart__order" v-for="item in orderInfo.basket.items" :key="item.id">
                            <h3>{{ item.product.title }}</h3>
                            <b>{{ item.product.price | numberFormat }} ₽</b>
                            <span>Количество: {{ item.quantity }}</span><br />
                            <span>Цвет: {{ item.color.color.title }}</span><br />
                            <span>Размер: {{ item.size.title }}</span><br />
                            <span>Артикул: {{ item.product.id }}</span>
                        </li>
                    </ul>

                    <div class="cart__total">
                        <p>{{ orderInfo.deliveryType.title }}:
                            <b v-if="orderInfo.deliveryType.price > 0">{{ orderInfo.deliveryType.price }} ₽</b>
                            <b v-else>бесплатно</b>
                        </p>
                        <p><b>{{ orderInfo.paymentType }}</b></p>
                        <p>Итого: <b>{{ orderInfo.basket.items.length }}</b> товара на сумму
                            <b>{{ totalPriceWithDelivery | numberFormat }} ₽</b></p>
                    </div>
                </div>
            </form>
        </section>
    </main>
</template>

<script>
    import numberFormat from '@/helpers/numberFormat';

    export default {
        name: 'OrderInfoPage',
        filters: { numberFormat },
        computed: {
            orderInfo() {
                return this.$store.state.orderInfo;
            },
            totalPriceWithDelivery() {
                return this.$store.state.orderInfo.basket.items
                    .reduce((acc, item) => (item.price * item.quantity) + acc, parseInt(this.$store.state.orderInfo.deliveryType.price));
            },
        },
        created() {
            if (this.$store.state.orderInfo && this.$store.state.orderInfo.id === this.$route.params.id) {
                return;
            }
            this.$store.dispatch('loadOrderInfo', this.$route.params.id);
        },
    };
</script>
