<template>
    <main class="content container">
        <div class="content__top">

            <div class="content__row">
                <h1 class="content__title">
                    Каталог
                </h1>
                <p><span class="content__info">Найдено товаров: <b>{{ countProducts }}</b>
                    Выводить товаров на страницу:</span>
                    <select v-model="productsPerPage">
                        <option value="3" selected>3</option>
                        <option value="6">6</option>
                        <option value="9">9</option>
                    </select>
                </p>
            </div>
        </div>

        <div class="content__catalog">
            <ProductFilter :category-id.sync="filterCategoryId" @apply-filter="setFilter"/>
            <section class="catalog">
                <PreLoader :loading="productsLoading" />
                <div v-if="productsLoading">Загрузка страницы...</div>
                <div v-if="productsLoadingFailed">Ошибка загрузки страницы...
                    <button @click.prevent="loadProducts">Попробовать еще раз</button>
                </div>
                <ProductList :products="products" />
                <BasePagination v-model="page" v-if="countProducts > productsPerPage" :count="countProducts"
                                :per-page="productsPerPage" @paginate="paginate"/>
            </section>
        </div>
    </main>
</template>

<script>
    import axios from 'axios';
    import {BASE_API_URL} from "@/config";
    import ProductList from "@/components/ProductList";
    import ProductFilter from "@/components/ProductFilter";
    import BasePagination from "@/components/BasePagination";
    import PreLoader from "@/components/PreLoader";

    export default {
        name: "MainPage",
        components: { ProductFilter, ProductList, BasePagination, PreLoader },
        data() {
            return {
                filterMaterialIds: [],
                filterSeasonIds: [],
                filterPriceFrom: 0,
                filterPriceTo: 0,
                filterCategoryId: this.$route.params.categoryId ? this.$route.params.categoryId : 0,
                page: 1,
                productsPerPage: 6,
                productData: null,
                productsLoading: false,
                productsLoadingFailed: false,
            };
        },
        computed: {
            products() {
                return this.productData ? this.productData.items : [];
            },
            countProducts() {
                return this.productData ? this.productData.pagination.total : 0;
            },
        },
        watch: {
            productsPerPage() {
                this.loadProducts();
            },
        },
        methods: {
            paginate(page) {
                this.page = page;
                this.loadProducts();
            },
            setFilter(p) {
                this.page = 1;
                this.filterCategoryId = p.categoryId;
                this.filterPriceFrom = p.priceFrom;
                this.filterPriceTo = p.priceTo;
                this.filterMaterialIds = p.materialIds;
                this.filterSeasonIds = p.seasonIds;
                this.loadProducts();

            },
            loadProducts() {
                const params = {
                    page: this.page,
                    limit: this.productsPerPage,
                    categoryId: this.filterCategoryId,
                    minPrice: this.filterPriceFrom,
                    maxPrice: this.filterPriceTo,
                    materialIds: this.filterMaterialIds,
                    seasonIds: this.filterSeasonIds,
                };
                this.productsLoading = true;
                clearTimeout(this.loadProductsTimer);
                this.loadProductsTimer = setTimeout(() => {
                    axios.get(`${BASE_API_URL}/api/products`, { params })
                        .then((response) => { this.productData = response.data; })
                        .catch(() => { this.productsLoadingFailed = true; })
                        .then(() => { this.productsLoading = false; });
                }, 500);
            },
        },
        created() {
            this.loadProducts();
        },
    };
</script>
