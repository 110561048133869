<template>
    <main class="content container">
        <div class="content__top">
            <ul class="breadcrumbs">
                <li class="breadcrumbs__item">
                    <router-link class="breadcrumbs__link" :to="{name: 'main'}">
                        Каталог
                    </router-link>
                </li>
                <li class="breadcrumbs__item">
                    <router-link class="breadcrumbs__link" :to="{name: 'cart'}">
                        Корзина
                    </router-link>
                </li>
                <li class="breadcrumbs__item">
                    <a class="breadcrumbs__link">
                        Оформление заказа
                    </a>
                </li>
            </ul>

            <div class="content__row">
                <h1 class="content__title">
                    Оформление заказа
                </h1>
            </div>
        </div>

<!--        <div v-if="deliveriesLoading">Загрузка страницы...</div>-->
        <div v-if="deliveriesLoadingFailed">Ошибка загрузки страницы...
            <button @click.prevent="loadDeliveries">Попробовать еще раз</button>
        </div>
<!--        <div v-if="paymentsLoading">Загрузка страницы...</div>-->
        <div v-if="paymentsLoadingFailed">Ошибка загрузки страницы...
            <button @click.prevent="loadPayments">Попробовать еще раз</button>
        </div>

        <section class="cart">
            <form class="cart__form form" action="#" method="POST" @submit.prevent="order">
                <div class="cart__field">
                    <div class="cart__data">

                        <BaseFormText v-model="formData.name" title="ФИО"
                                      :error="formError.name" placeholder="Введите ваше полное имя" />

                        <BaseFormText v-model="formData.address" title="Адрес доставки"
                                      :error="formError.address" placeholder="Введите ваш адрес" />

                        <BaseFormText v-model="formData.phone" title="Телефон"
                                      :error="formError.phone" placeholder="Введите ваш телефон" />

                        <BaseFormText v-model="formData.email" title="E-mail"
                                      :error="formError.email" placeholder="Введите ваш Email" />

                        <BaseFormTextarea v-model="formData.comment" title="Комментарий к заказу"
                                          :error="formError.comment" placeholder="Ваши пожелания" />

                    </div>

                    <div class="cart__options">
                        <h3 class="cart__title">Доставка</h3>
                        <ul class="cart__options options">
                            <li class="options__item" v-for="delivery in deliveriesData" :key="delivery.id">
                                <label class="options__label">
                                    <input class="options__radio sr-only" type="radio" :value="delivery.id"
                                           @click.prevent="selectDelivery(delivery)"
                                           :checked="delivery.id === deliveryTypeId">
                                    <span class="options__value">{{ delivery.title }}
                                        <b v-if="delivery.price > 0">{{ delivery.price | numberFormat }} ₽</b>
                                        <b v-else>бесплатно</b>
                                    </span>
                                </label>
                            </li>
                        </ul>

                        <h3 class="cart__title">Оплата</h3>
                        <ul class="cart__options options">
                            <li class="options__item" v-for="payment in paymentsData" :key="payment.id">
                                <label class="options__label">
                                    <input class="options__radio sr-only" type="radio" :value="payment.id"
                                           @click="selectPayment(payment)"
                                           :checked="payment.id === paymentTypeId" >
                                    <span class="options__value">{{ payment.title }}</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="cart__block">
                    <ul class="cart__orders">
                        <li class="cart__order" v-for="item in cartItems" :key="item.id">
                            <h3>{{ item.product.title }}</h3>
                            <b>{{ (item.product.price * item.quantity) || numberFormat }} ₽</b>
                            Количество: {{ item.quantity }}<br/>
                            Цвет: {{ item.color.color.title }}<br/>
                            Размер: {{ item.size.title }}<br/>
                            <span>Артикул: {{ item.productId }}</span>
                        </li>
                    </ul>

                    <div class="cart__total">
                        <p>{{ deliveryMethod }}
                            <b v-if="deliveryPrice > 0">{{ deliveryPrice | numberFormat }} ₽</b>
                            <b v-else-if="deliveryTypeId">Бесплатно</b>
                        </p>
                        <p>{{ paymentMethod }}</p>
                        <p>Итого: <b>{{ cartItems.length }}</b> товар(-ов) на сумму <b>{{ grandTotalPrice | numberFormat }} ₽</b></p>
                    </div>

                    <button v-if="deliveryTypeId && paymentTypeId" class="cart__button button button--primery" type="submit">
                        Оформить заказ
                    </button>
                </div>
                <div class="cart__error form__error-block" v-if="formErrorMessage">
                    <h4>Заявка не отправлена!</h4>
                    <p>
                        {{ formErrorMessage }}
                    </p>
                </div>
            </form>
        </section>
    </main>
</template>

<script>
    import BaseFormText from '@/components/BaseFormText.vue';
    import BaseFormTextarea from '@/components/BaseFormTextarea.vue';
    import axios from 'axios';
    import {BASE_API_URL} from '@/config';
    import { mapGetters } from 'vuex';
    import numberFormat from '@/helpers/numberFormat';

    export default {
        name: 'OrderPage',
        components: { BaseFormText, BaseFormTextarea },
        filters: { numberFormat },
        data() {
            return {
                formData: {},
                formError: {},
                formErrorMessage: '',
                deliveryPrice: 0,
                deliveryMethod: 'Способ доставки не выбран',
                deliveryTypeId: 0,
                deliveriesData: [],
                deliveriesLoading: false,
                deliveriesLoadingFailed: false,
                paymentTypeId: 0,
                paymentMethod: 'Способ оплаты не выбран',
                paymentsData: [],
                paymentsLoading: false,
                paymentsLoadingFailed: false,
            };
        },
        computed: {
            ...mapGetters({
                cartItems: 'cartDetailProducts',
                totalPrice: 'cartTotalPrice',
            }),
            grandTotalPrice() {
                return parseInt(this.totalPrice) + parseInt(this.deliveryPrice);
            },
        },
        methods: {
            order() {
                this.formError = {};
                this.formErrorMessage = '';
                axios
                    .post(`${BASE_API_URL}/api/orders`, {
                        ...this.formData,
                        deliveryTypeId: this.deliveryTypeId,
                        paymentTypeId: this.paymentTypeId,
                    }, {
                        params: {
                            userAccessKey: this.$store.state.userAccessKey,
                        },
                    })
                    .then((response) => {
                        this.$store.commit('updateOrderInfo', response.data);
                        this.$store.commit('resetCart');
                        this.$router.push({
                            name: 'orderInfo',
                            params: { id: response.data.id },
                        });
                    })
                    .catch((error) => {
                        this.formError = error.response.data.error.request || {};
                        this.formErrorMessage = error.response.data.error.message;
                    });
            },
            selectDelivery(delivery) {
                this.deliveryTypeId = delivery.id;
                this.loadPayments(delivery.id);
                this.paymentTypeId = 0;
                this.paymentMethod = 'Способ оплаты не выбран';
                this.deliveryPrice = delivery.price;
                this.deliveryMethod = delivery.title;
            },
            selectPayment(payment) {
                this.paymentTypeId = payment.id;
                this.paymentMethod = payment.title;
            },
            loadDeliveries() {
                this.deliveriesLoading = true;
                this.deliveriesLoadingFailed = false;
                axios
                    .get(`${BASE_API_URL}/api/deliveries`)
                    .then((response) => { this.deliveriesData = response.data; })
                    .catch(() => { this.deliveriesLoadingFailed = true; })
                    .then(() => { this.deliveriesLoading = false; });
            },
            loadPayments(deliveryTypeId) {
                this.paymentsLoading = true;
                this.paymentsLoadingFailed = false;
                axios
                    .get(`${BASE_API_URL}/api/payments`, {
                        params: {
                            deliveryTypeId
                        }
                    })
                    .then((response) => { this.paymentsData = response.data; })
                    .catch(() => { this.paymentsLoadingFailed = true; })
                    .then(() => { this.paymentsLoading = false; });
            },
        },
        created() {
            this.loadDeliveries();
        },
    };
</script>
