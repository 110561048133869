<template>
<div>
    <header class="header container">
        <div class="header__wrapper">
            <router-link :to="{name: 'main'}">
                <span class="header__info">Каталог</span>
            </router-link>

            <router-link class="header__logo" :to="{name: 'main'}">
                <img src="img/svg/logo-moire.svg" alt="Логотип интернет магазина Moire" width="116" height="34">
            </router-link>

            <a class="header__tel" :href="`tel:${shopPhone}`">
                {{ shopPhone }}
            </a>

            <CartIndicator />
        </div>
    </header>
    <router-view />
    <footer class="footer container">
        <div class="footer__wrapper">
            <ul class="footer__links">
                <li>
                    <router-link class="footer__link" :to="{name: 'main', params: {categoryId: 0}}">
                        Каталог
                    </router-link>
                </li>
                <li>
                    <a class="footer__link" :href="`tel:${shopPhone}`">
                        {{ shopPhone }}
                    </a>
                </li>
                <li>
                    <a class="footer__link" :href="`mailto:${shopEmail}`">
                        {{ shopEmail }}
                    </a>
                </li>
                <li>
                    <a class="footer__link" href="#">
                        Распродажа
                    </a>
                </li>
                <li>
                    <a class="footer__link footer__link--medium" href="#">
                        Заказать звонок
                    </a>
                </li>
            </ul>

            <ul class="footer__social social">
                <li class="social__item">
                    <a class="social__link" href="#" aria-label="Вконтакте">
                        <svg width="20" height="11" fill="currentColor">
                            <use xlink:href="#icon-vk"></use>
                        </svg>
                    </a>
                </li>
                <li class="social__item">
                    <a class="social__link" href="#" aria-label="Инстаграм">
                        <svg width="17" height="17" fill="currentColor">
                            <use xlink:href="#icon-insta"></use>
                        </svg>
                    </a>
                </li>
                <li class="social__item">
                    <a class="social__link" href="#" aria-label="Facebook">
                        <svg width="17" height="17" fill="currentColor">
                            <use xlink:href="#icon-facebook"></use>
                        </svg>
                    </a>
                </li>
                <li class="social__item">
                    <a class="social__link" href="#" aria-label="Twitter">
                        <svg width="17" height="14" fill="currentColor">
                            <use xlink:href="#icon-twitter"></use>
                        </svg>
                    </a>
                </li>
                <li class="social__item">
                    <a class="social__link" href="#" aria-label="Telegram">
                        <svg width="19" height="17" fill="currentColor">
                            <use xlink:href="#icon-telegram"></use>
                        </svg>
                    </a>
                </li>
            </ul>

            <p class="footer__desc">
                Данный сайт выполнен в рамках дипломного проекта Бориса Грибовского.
                Сайт не является интернет-магазином, создан в демонстрационных целях.
            </p>

            <ul class="footer__data">
                <li>
                    <router-link class="footer__link" :to="{name: 'privacy'}" rel="noopener">
                        Политика конфиденциальности
                    </router-link>
                </li>
                <li>
                    <router-link class="footer__link" :to="{name: 'privacy'}">
                        Публичная оферта
                    </router-link>
                </li>
            </ul>

            <span class="footer__copyright">
        © 2022 Moire
      </span>
        </div>
    </footer>
</div>
</template>

<script>
import CartIndicator from "@/components/CartIndicator";
import {mapActions, mapMutations} from "vuex";
import {config} from '@/config';

export default {
    name: 'App',
    components: {CartIndicator},
    data() {
        return {
            shopPhone: config.shopPhone,
            shopEmail: config.shopEmail,
        };
    },
    created() {
        const userAccessKey = localStorage.getItem('userAccessKey');
        if (userAccessKey) {
            this.updateUserAccessKey(userAccessKey);
        }

        // this.$store.dispatch('loadCart');
        this.loadCart();
    },
    methods: {
        ...mapActions(['loadCart']),
        ...mapMutations(['updateUserAccessKey']),
    },
}
</script>

