<template>
    <li class="catalog__item">
        <router-link class="catalog__pic" :to="{name: 'product', params: {id: product.id, colorId: currentColorId}}">
            <img :src="imageSrc" :alt="product.title">
        </router-link>

        <h3 class="catalog__title">
            <a href="#">
                {{ product.title }}
            </a>
        </h3>

        <span class="catalog__price">
              {{ product.price | numberFormat }} ₽
            </span>

        <ColorSelector :colors="product.colors" :current-color-id.sync="currentColorId"/>
    </li>
</template>

<script>
    import numberFormat from '@/helpers/numberFormat';
    import ColorSelector from '@/components/ColorSelector.vue';

    export default {
        name: 'ProductItem',
        props: ['product'],
        components: {ColorSelector},
        data() {
            return {
                currentColorId: this.product.colors[0].color.id,
            };
        },
        computed: {
            currentColor() {
                return this.product.colors.find(e => e.color.id === this.currentColorId)
            },
            imageSrc() {
                if (this.currentColor.gallery) {
                    return this.currentColor.gallery[0].file.url;
                }
                return 'https://via.placeholder.com/270x350.png?text=Нет+изображения';
            },
        },
        filters: {
            numberFormat,
        },
    };
</script>
