<template>
    <li class="cart__item product" :key="item.itemId">
        <div class="product__pic">
            <img v-if="item.product.colors[colorIndex].gallery" width="120" height="120"
                 :src="item.product.colors[colorIndex].gallery[0].file.url" :alt="item.product.title">
            <img v-else src="https://via.placeholder.com/120.png?text=Нет+изображения" :alt="item.product.title">
        </div>
        <h3 class="product__title">
            {{ item.product.title }}
        </h3>
        <p class="product__info product__info--color">
            Цвет:
            <span>
                  <i :style="`background-color: ${item.product.colors[colorIndex].color.code}`"></i>
                  {{ item.product.colors[colorIndex].color.title }}
                </span>
            Размер: {{ item.size.title }}
        </p>

        <span class="product__code">Артикул: {{ item.productId }}</span>

        <AmountSelector :value="item.quantity" @update-amount="updateAmount"/>

        <b class="product__price">
            {{ itemPrice | numberFormat }} ₽
        </b>

        <button class="product__del button-del" type="button"
                aria-label="Удалить товар из корзины" @click.prevent="deleteCartItem(item.itemId)">
            <svg width="20" height="20" fill="currentColor">
                <use xlink:href="#icon-close"></use>
            </svg>
        </button>
    </li>
</template>

<script>
    import AmountSelector from "@/components/AmountSelector";
    import numberFormat from '@/helpers/numberFormat';
    import { mapActions } from 'vuex';

    export default {
        name: 'CartItem',
        components: { AmountSelector },
        filters: {
            numberFormat,
        },
        props: ['item'],
        data() {
            return {
                num: this.item.quantity,
                itemPrice: this.item.product.price * this.item.quantity,
            };
        },
        computed: {
            colorIndex() {
                for (let i = 0; i < this.item.product.colors.length; i++) {
                    if (this.item.product.colors[i].color.id === this.item.colorId) {
                        return i;
                    }
                }
                return 0;
            },
        },
        methods: {
            ...mapActions({ deleteCartItem: 'deleteCartItem' }),
            updateAmount(value) {
                this.item.quantity = Math.trunc(value);
                this.itemPrice = this.item.product.price * this.item.quantity;
                this.$emit('update-item', {
                    basketItemId: this.item.itemId,
                    quantity: Math.trunc(value),
                });
            },
        },
    };
</script>
