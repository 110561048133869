<template>
    <main class="content container">
        <div class="content__top">
            <ul class="breadcrumbs">
                <li class="breadcrumbs__item">
                    <router-link class="breadcrumbs__link" :to="{name: 'main'}">
                        Каталог
                    </router-link>
                </li>
                <li class="breadcrumbs__item">
                    <a class="breadcrumbs__link">
                        Корзина
                    </a>
                </li>
            </ul>

            <div class="content__row">
                <h1 class="content__title">
                    Корзина
                </h1>
                <span class="content__info">Наименований товаров:{{ cartItems.length }}</span>
            </div>
        </div>

        <section class="cart">
            <form class="cart__form form" action="#" method="POST">
                <div class="cart__field">
                    <ul class="cart__list">
                        <CartItem v-for="item in products" :key="item.id"
                                  :item="item" @update-item="updateItem"/>
                    </ul>
                </div>

                <div class="cart__block">
                    <p class="cart__desc">
                        Мы посчитаем стоимость доставки на следующем этапе
                    </p>
                    <p class="cart__price">
                        Итого: <span>{{ totalPrice | numberFormat }} ₽</span>
                    </p>

                    <router-link v-if="cartNotEmpty" :to="{name: 'order'}">
                        <button class="cart__button button button--primery" type="submit">
                            Оформить заказ
                        </button>
                    </router-link>
                </div>
            </form>
        </section>
    </main>
</template>

<script>
    import numberFormat from '@/helpers/numberFormat';
    import { mapGetters } from 'vuex';
    import CartItem from '@/components/CartItem.vue';

    export default {
        name: 'CartPage',
        components: { CartItem },
        filters: { numberFormat },
        computed: {
            ...mapGetters({
                cartItems: 'cartDetailProducts',
                totalPrice: 'cartTotalPrice',
            }),
            products() {
                return this.$store.getters.cartDetailProducts;
            },
            cartNotEmpty() {
                return this.$store.state.cartProducts.length > 0;
            },
        },
        methods: {
            updateItem(data) {
                this.$store.dispatch('updateCartProductAmount', data);
            },
        },
    };
</script>
