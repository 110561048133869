<template>
    <main class="content container" v-if="productLoading">Загрузка страницы...</main>
    <main class="content container" v-else-if="!productData">Не удалось загрузить стр...</main>
    <main class="content container" v-else>
        <div class="content__top">
            <ul class="breadcrumbs">
                <li class="breadcrumbs__item">
                    <router-link class="breadcrumbs__link" :to="{name: 'main'}">
                        Каталог
                    </router-link>
                </li>
                <li class="breadcrumbs__item">
                    <router-link class="breadcrumbs__link" :to="{name: 'main', params: {categoryId: category.id}}">
                        {{ category.title }}
                    </router-link>
                </li>
                <li class="breadcrumbs__item">
                    <a class="breadcrumbs__link">
                        {{ product.title }}
                    </a>
                </li>
            </ul>
        </div>

        <section class="item">
            <div class="item__pics pics">
                <div class="pics__wrapper">
                    <img width="570" height="570" :src="imageSrc" :alt="product.title">
                </div>
                <ul class="pics__list">
                    <li class="pics__item" v-for="(color, index) in product.colors" :key="index">
                        <a href="" class="pics__link" :class="{pics__link__current: productColorId === product.colors[index].color.id}"
                           @click.prevent="productColorId = product.colors[index].color.id">
                            <img width="98" height="98" v-if="product.colors[index].gallery"
                                 :src="product.colors[index].gallery[0].file.url" :alt="product.title">
                        </a>
                    </li>
                </ul>
            </div>

            <div class="item__info">
                <span class="item__code">Артикул: {{ product.id }}</span>
                <h2 class="item__title">
                    {{ product.title }}
                </h2>
                <div class="item__form">
                    <form class="form" action="#" method="POST" @submit.prevent="addToCart">
                        <div class="item__row item__row--center">
                            <AmountSelector :value="productAmount" @update-amount="updateAmount"/>

                            <b class="item__price">
                                {{ product.price | numberFormat }} ₽
                            </b>
                        </div>

                        <div class="item__row">
                            <fieldset class="form__block">
                                <legend class="form__legend">Цвет</legend>
                                <ColorSelector :colors="product.colors" :current-color-id.sync="productColorId" />
                            </fieldset>


                            <fieldset class="form__block">
                                <legend class="form__legend">Размер</legend>
                                <SizeSelector :sizes="product.sizes" :current-size-id.sync="productSizeId" />
                            </fieldset>
                        </div>

                        <button class="item__button button button--primery" type="submit" :disabled="productAddSending">
                            В корзину
                        </button>
                        <div v-show="productAdded">Товар добавлен в корзину</div>
                        <div v-show="productAddSending">Товар добавляется в корзину...</div>
                    </form>
                </div>
            </div>

            <div class="item__desc">
                <ul class="tabs">
                    <li class="tabs__item">
                        <a class="tabs__link" :class="{tabs__link__current: showInfo}" @click.prevent="displayInfo">
                            Информация о товаре
                        </a>
                    </li>
                    <li class="tabs__item">
                        <a class="tabs__link" :class="{tabs__link__current: !showInfo}" @click.prevent="displayDelivery">
                            Доставка и возврат
                        </a>
                    </li>
                </ul>

                <div class="item__content" v-if="showInfo">
                    <h3>Состав:</h3>
                    <p>{{ materialList }}</p>

                    <h3>Коллекции:</h3>
                    <p>{{ seasonList }}</p>

                    <h3>Категория товара:</h3>
                    <p>{{ category.title }}</p>

                    <h3>Уход:</h3>
                    <p>
                        Машинная стирка при макс. 30ºC короткий отжим<br>
                        Гладить при макс. 110ºC<br>
                        Не использовать машинную сушку<br>
                        Отбеливать запрещено<br>
                        Не подвергать химчистке<br>
                    </p>

                </div>
                <div class="item__content" v-if="!showInfo">
                    <h3>Доставка:</h3>
                    <p>Самовывоз (бесплатно) или курьером.</p>

                    <h3>Возврат:</h3>
                    <p>
                        Не подлежит возврату нижнее белье
                    </p>

                </div>
            </div>
        </section>
    </main>
</template>

<script>
    import numberFormat from "@/helpers/numberFormat";
    import axios from "axios";
    import {BASE_API_URL} from "@/config";
    import { mapActions } from 'vuex';
    import AmountSelector from "@/components/AmountSelector";
    import ColorSelector from "@/components/ColorSelector";
    import SizeSelector from "@/components/SizeSelector";

    export default {
        name: "ProductPage",
        filters: {
            numberFormat,
        },
        components: {SizeSelector, ColorSelector, AmountSelector },
        data() {
            return {
                productAmount: 1,
                productData: null,
                productLoading: false,
                productLoadingFailed: false,
                productAdded: false,
                productAddSending: false,
                productColorId: this.$route.params.colorId || 0,
                productSizeId: 0,
                showInfo: true,
            };
        },
        computed: {
            product() {
                return this.productData;
            },
            category() {
                return this.productData.category;
            },
            materialList() {
                let matList = this.productData.materials.map((m) => m.title);
                return matList.join(', ');
            },
            seasonList() {
                let seasList = this.productData.seasons.map((s) => s.title);
                return seasList.join(', ');
            },
            productColor() {
                if (this.productColorId === 0) {
                    return this.product.colors[0];
                }
                return this.product.colors.find(e => e.color.id === this.productColorId)
            },
            imageSrc() {
                if (this.productColor.gallery) {
                    return this.productColor.gallery[0].file.url;
                }
                return 'https://via.placeholder.com/270x350.png?text=Нет+изображения';
            },
            productSize() {
                if (this.productSizeId === 0) {
                    return this.product.sizes[0];
                }
                return this.product.sizes.find(e => e.id === this.productSizeId)
            }
        },
        methods: {
            ...mapActions(['addProductToCart']),
            updateAmount(value) {
                this.productAmount = value;
            },
            loadProduct() {
                this.productLoading = true;
                this.productLoadingFailed = false;
                axios
                    .get(`${BASE_API_URL}/api/products/${this.$route.params.id}`)
                    .then((response) => {
                        this.productData = response.data;
                        if (this.productColorId === 0) {
                            this.productColorId = this.productData.colors[0].color.id;
                        }
                        this.productSizeId = this.productData.sizes[0].id;

                    })
                    .catch(() => {
                        this.productLoadingFailed = true;

                    })
                    .then(() => { this.productLoading = false; });
            },
            addToCart() {
                this.productAdded = false;
                this.productAddSending = true;
                this.$store.dispatch('addProductToCart', {
                    quantity: this.productAmount,
                    productId: this.product.id,
                    colorId: this.productColorId,
                    sizeId: this.productSizeId,
                })
                    .then(() => {
                        this.productAdded = true;
                        this.productAddSending = false;
                    });
            },
            displayInfo() {
                this.showInfo = true;
            },
            displayDelivery() {
                this.showInfo = false;
            },
        },
        created() {
            this.loadProduct();
        },
        watch: {
            '$route.params.id': function () {
                this.loadProduct();
            },
        },
    }
</script>

<style>
    .tabs__link {
        cursor: pointer;
    }
    .tabs__link.tabs__link__current {
        cursor: unset;
    }
</style>
