<template>
    <aside class="filter">
        <form class="filter__form form" action="#" method="get">
            <fieldset class="form__block">
                <legend class="form__legend">Цена</legend>
                <label class="form__label form__label--price">
                    <input class="form__input" type="text" name="min-price" v-model.number="filterPriceFrom">
                    <span class="form__value">От</span>
                </label>
                <label class="form__label form__label--price">
                    <input class="form__input" type="text" name="max-price" v-model.number="filterPriceTo">
                    <span class="form__value">До</span>
                </label>
            </fieldset>

            <fieldset class="form__block">
                <legend class="form__legend">Категория</legend>
                <label class="form__label form__label--select">
                    <select class="form__select" type="text" name="category"
                        v-model.number="filterCategoryId">
                        <option value="0">Все категории</option>
                        <option :value="category.id" v-for="category in categories" :key="category.id">
                            {{ category.title }}</option>
                    </select>
                </label>
            </fieldset>

            <fieldset class="form__block">
                <legend class="form__legend">Материал</legend>
                <CheckboxSelector :options.sync="materials" :initial.sync="filterMaterialIds" @options-changed="fixMaterials"/>

            </fieldset>

            <fieldset class="form__block">
                <legend class="form__legend">Коллекция</legend>
                <CheckboxSelector :options.sync="seasons" :initial.sync="filterSeasonIds" @options-changed="fixSeasons"/>
            </fieldset>

            <button class="filter__submit button button--primery" type="submit" @click.prevent="submit">
                Применить
            </button>
            <button class="filter__reset button button--second" type="button" @click.prevent="reset">
                Сбросить
            </button>
        </form>
    </aside>
</template>

<script>
    import axios from "axios";
    import {BASE_API_URL} from "@/config";
    import CheckboxSelector from "./CheckboxSelector";

    export default {
        name: "ProductFilter",
        components: {CheckboxSelector},
        props: ['categoryId'],
        data() {
            return {
                filterMaterialIds: [],
                filterSeasonIds: [],
                filterPriceFrom: 0,
                filterPriceTo: 0,
                filterCategoryId: this.categoryId,
                categoriesData: null,
                colorsData: null,
                materialsData: null,
                seasonsData: null,
            };
        },
        computed: {
            categories() {
                return this.categoriesData ? this.categoriesData.items : [];
            },
            colors() {
                return this.colorsData ? this.colorsData.items : [];
            },
            materials() {
                return this.materialsData ? this.materialsData.items : [];
            },
            seasons() {
                return this.seasonsData ? this.seasonsData.items : [];
            },
        },
        methods: {
            fixMaterials(value) {
                this.filterMaterialIds = value;
            },
            fixSeasons(value) {
                this.filterSeasonIds = value;
            },
            submit() {
                this.$emit('apply-filter', {
                    categoryId: this.filterCategoryId,
                    priceFrom: this.filterPriceFrom,
                    priceTo: this.filterPriceTo,
                    materialIds: this.filterMaterialIds,
                    seasonIds: this.filterSeasonIds,
                });
            },
            reset() {
                this.filterCategoryId = 0;
                this.filterPriceFrom = 0;
                this.filterPriceTo = 0;
                this.filterMaterialIds = [];
                this.filterSeasonIds = [];
                this.submit();
            },
            loadCategories() {
                axios.get(`${BASE_API_URL}/api/productCategories`)
                    .then((response) => {
                        this.categoriesData = response.data;
                    });
            },
            loadColors() {
                axios.get(`${BASE_API_URL}/api/colors`)
                    .then((response) => {
                        this.colorsData = response.data;
                    });
            },
            loadSeasons() {
                axios.get(`${BASE_API_URL}/api/seasons`)
                    .then((response) => {
                        this.seasonsData = response.data;
                    });
            },
            loadMaterials() {
                axios.get(`${BASE_API_URL}/api/materials`)
                    .then((response) => {
                        this.materialsData = response.data;
                    });
            },
        },
        created() {
            this.loadCategories();
            this.loadColors();
            this.loadSeasons();
            this.loadMaterials();
        },
    }
</script>
