<template>
    <div class="form__counter">
        <button type="button" aria-label="Убрать один товар" @click.prevent="decrease">
            <svg width="12" height="12" fill="currentColor">
                <use xlink:href="#icon-minus"></use>
            </svg>
        </button>

        <input type="number" min="1" step="1" v-model.number.lazy="amount" name="count">

        <button type="button" aria-label="Добавить один товар" @click.prevent="increase">
            <svg width="12" height="12" fill="currentColor">
                <use xlink:href="#icon-plus"></use>
            </svg>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'AmountSelector',
        props: ['value'],
        data() {
            return {
                amount: this.value,
            };
        },
        watch: {
            amount() {
                this.$emit('update-amount', Math.trunc(this.amount));
            },
        },
        methods: {
            decrease() {
                if (this.amount > 1) {
                    this.amount -= 1;
                }
            },
            increase() {
                this.amount += 1;
            },
        },
    };
</script>

<style>
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
    input[type="number"]:hover,
    input[type="number"]:focus {
        -moz-appearance: number-input;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
</style>

