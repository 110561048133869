<template>
    <main class="content container">
        <h1>Страница не найдена</h1>
    </main>
</template>

<script>
    export default {
        name: "NotFoundPage"
    }
</script>

<style scoped>

</style>
