import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";
import router from "@/router";
import {BASE_API_URL} from '@/config';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        cartProducts: [],
        userAccessKey: null,
        cartProductsData: [],
        orderInfo: null,
    },
    mutations: {
        updateOrderInfo(state, orderInfo) {
            state.orderInfo = orderInfo;
        },
        resetCart(state) {
            state.cartProducts = [];
            state.cartProductsData = [];
        },
        updateCartProductAmount(state, { basketItemId, quantity }) {
            const item = state.cartProducts.find((x) => {
                return x.id === basketItemId;
            });
            if (item) {
                item.quantity = quantity;
            }
        },
        deleteCartItem(state, cartItemId) {
            state.cartProducts = state.cartProducts.filter((item) => item.itemId !== cartItemId);
        },
        updateUserAccessKey(state, accessKey) {
            state.userAccessKey = accessKey;
        },
        updateCartProductData(state, items) {
            state.cartProductsData = items;
        },
        syncCartProducts(state) {
            state.cartProducts = state.cartProductsData.map((item) => (
                {
                    itemId: item.id,
                    productId: item.product.id,
                    colorId: item.color.color.id,
                    sizeId: item.size.id,
                    quantity: item.quantity,
                }));
        },
    },
    getters: {
        cartDetailProducts(state) {
            return state.cartProducts.map((item) => {
                const cartItem = state.cartProductsData.find((p) => p.id === item.itemId);
                return {
                    ...item,
                    product: cartItem.product,
                    size: cartItem.size,
                    color: cartItem.color,
                };
            });
        },
        cartTotalPrice(state, getters) {
            return getters.cartDetailProducts
                .reduce((acc, item) => (item.product.price * item.quantity) + acc, 0);
        },
    },
    actions: {
        loadOrderInfo(context, orderId) {
            return axios
                .get(`${BASE_API_URL}/api/orders/${orderId}`, {
                    params: { userAccessKey: context.state.userAccessKey },
                })
                .then((response) => {
                    context.commit('updateOrderInfo', response.data);
                })
                .catch(() => {
                    router.replace({name: 'notFound'})
                });
        },
        loadCart(context) {
            return axios
                .get(`${BASE_API_URL}/api/baskets`, {
                    params: { userAccessKey: context.state.userAccessKey },
                })
                .then((response) => {
                    if (!context.state.userAccessKey) {
                        localStorage.setItem('userAccessKey', response.data.user.accessKey);
                        context.commit('updateUserAccessKey', response.data.user.accessKey);
                    }
                    context.commit('updateCartProductData', response.data.items);
                    context.commit('updateUserAccessKey', response.data.user.accessKey);
                    context.commit('syncCartProducts');
                });
        },
        addProductToCart(context, { productId, colorId, sizeId, quantity }) {
            return axios.post(`${BASE_API_URL}/api/baskets/products`, {
                productId,
                colorId,
                sizeId,
                quantity,
            }, {
                params: {
                    userAccessKey: context.state.userAccessKey,
                },
            })
                .then((response) => {
                    context.commit('updateCartProductData', response.data.items);
                    context.commit('updateUserAccessKey', response.data.user.accessKey);
                    context.commit('syncCartProducts');
                });
        },
        updateCartProductAmount(context, { basketItemId, quantity }) {
            context.commit('updateCartProductAmount', { basketItemId, quantity });
            return axios.put(`${BASE_API_URL}/api/baskets/products`, {
                basketItemId,
                quantity,
            }, {
                params: {
                    userAccessKey: context.state.userAccessKey,
                },
            })
                .then((response) => {
                    context.commit('updateCartProductData', response.data.items);
                    context.commit('syncCartProducts');
                })
                .catch(() => {
                    context.commit('syncCartProducts');
                });
        },
        deleteCartItem(context, cartItemId) {
            context.commit('deleteCartItem', cartItemId);

            return axios.delete(`${BASE_API_URL}/api/baskets/products`, {
                data: {
                    basketItemId: cartItemId,
                },
                params: {
                    userAccessKey: context.state.userAccessKey,
                },
            })
                .then((response) => {
                    context.commit('deleteCartItem', response.data.items);
                })
                .catch(() => {
                    context.commit('syncCartProducts');
                });
        },
    },
});
